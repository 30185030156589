import React, {useEffect, useState} from "react";
import general from "../../general";
import {Link} from "react-router-dom";
import Image from "../../lib/Image";
import Dialog from "../../lib/Dialog";
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import {CSSTransition} from "react-transition-group";
import {NavLink} from "../../lib/Navigation";
import moment from "moment";
import useActiveState from "../../lib/useActiveState";
import {useHistory} from "react-router-dom";
import { ContactsOutlined } from "@ant-design/icons";
import Subtitle from "../components/Subtitle";

export default ({query, setTitle}) => {
  const {aplus_queue} = window;
  const history = useHistory();
  const [data, setData] = useState({});
  const [code, setCode] = useState({});
  const [giftShow, setGiftShow] = useState();
  const [gift, setGift] = useState({});
  const [giftCode, setGiftCode] = useState();
  const [giftDescInfo, setGiftDescInfo] = useState({});
  const [giftDescInfoImage, setGiftDescInfoImage] = useState({});
  const [user, setUser] = useActiveState('user');
  const [trumpetState, setTrumpetState] = useState(false);//是否有小号状态--领取礼包
  const [gamePubId, setGamePubId] = useState();
  const [agent] = useActiveState('agent');

  const [projectGameslist, setProjectGameslist] = useState();

  const [randomColorInfo] = useActiveState('randomColorInfo');
  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    if (localStorage.getItem('username')){
      load();
    }
  }, [localStorage.getItem('username')]);

  useEffect(() => {
    if(history.action == 'POP' && history.location.pathname == '/game/project') {
      load();
    }
  }, [history.action, history.location.pathname]);

  // useEffect(() => {
  //   load();
  // }, [user]);

  const load = () => {
    Dialog.loading();
    general.axios.post('/base/special/getData', {id: query.id})
      .finally(() => {
        Dialog.close();
      })
      .then(res => {
        let {status, data} = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }
        setTitle(data.title);
        setData(data);

        data.projectGameslist.forEach(d =>{
          d.infoColor = randomColorInfo[parseInt(Math.random() * 4)]
        })
        setProjectGameslist(data.projectGameslist);
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  }

  const receive = item => {
    // console.log(item);
    Dialog.loading();
    general.axios.post('https://api3.app.99maiyou.com/queryGameAccount', {maiyou_gameid: item.maiyou_gameid})
      .finally(() => {
        Dialog.close();
      })
      .then(res => {
        let {status, data} = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }
        if(data.isExists) {
          Dialog.loading();
          general.axios.post('/base/game/getReceiveGift', {gift_id: item.gift_id})
            .finally(() => {
              Dialog.close();
            })
            .then(res => {
              let {status, data} = res.data;
              if (!status.succeed) {
                Dialog.error(status.error_desc);
                return;
              } else {
                setGiftShow(false);
                setGiftCode(data);
                Dialog.info("领取成功");
              }
            })
            .catch(err => {
              Dialog.error(err.message);
            });
        } else {
          // setGiftShow(false);
          setTrumpetState(true);
        }
      })
      .catch(err => {
        Dialog.error(err.message);
      });


    // console.log(item);
    // Dialog.loading();
    // general.axios.post('/base/game/getReceiveGift', {gift_id: item.gift_id})
    //   .finally(() => {
    //     Dialog.close();
    //   })
    //   .then(res => {
    //     let {status, data} = res.data;
    //     if (!status.succeed) {
    //       Dialog.error(status.error_desc);
    //       return;
    //     }
    //     setGiftShow(false);
    //     setGiftCode(data);
    //     Dialog.info("领取成功");
    //     load();
    //   })
    //   .catch(err => {
    //     Dialog.error(err.message);
    //   });
  }

  const bouncesShow = item => {
    Dialog.loading();
    general.axios.post('/base/gift/getGiftDetail', {gift_id: item.packid})
      .finally(() => {
        Dialog.close();
      })
      .then(res => {
        let {status, data} = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }
        let descData = data.gift_info;
        setGiftDescInfo(data.gift_info);
        setGiftDescInfoImage(descData.new_image.thumb);
        
        // setGift(item);
        setGiftShow(true);
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  }

  const receiveVouNew = item => {
    // console.log(item);
    general.axios.post('/receiveVoucher', {voucher_id: item.voucherId})
    .finally(() => {
        Dialog.close();
    })
    .then(res => {
        let {status, data} = res.data;
        if (!status.succeed) {
            Dialog.error(status.error_desc);
        } else {
          let available_voucher = (parseInt(user.available_voucher) + 1).toString();
          setUser({...user, ...{available_voucher: available_voucher}});
          Dialog.success('领取成功');

          // if(!data.hasSome) {
          //   item.received = true;
          //   update();
          // }
          load();
        // item.is_received = true;
        // update();
        }
    })
    .catch(err => {
        Dialog.error(err.message);
    });
  }

  const activityData = item => {
    // if(data.fuli_type == "news") {
    //   history.push('/game?id='+item.game_id+'&newid='+item.newid+'&source=project');
    // } else {
    //   history.push('/game?id='+item.game_id);
    // }

    if(data.type == 2 && data.fuli_type == "news") {
      if(item.newid) {
        history.push('/game?id='+item.game_id+'&newid='+item.newid+'&source=project');
      } else {
        history.push('/game?id='+item.game_id);
      }
    } else {
      history.push('/game?id='+item.game_id);
    }
    
    aplus_queue.push({
      action: 'aplus.record',
      arguments: ['ClickTheGameInTheTopic', 'CLK', {age:user?.age, level: user?.vip_level, sex: user.sex == 0 ? '未知' : user.sex == 1 ? '男' : '女', username: localStorage.getItem('username'), gamename: item.game_name, agent: agent, project_name: data.title}]
    });
  }

  // <div className={'game-project style-' + data.style} /*style={{backgroundColor: data.big_block_color}}*/>
  return <div className={'game-project style-' + data.style} style={{backgroundColor: '#f5f6f8'}}>
    <div className="project-img"><img src={data.top_image?data.top_image:'/resources/index/projectdefault.png'} alt="" /></div>
    {/* <div className="project-info" style={{backgroundColor: data.tips_bg_color, color: data.tips_font_color}}>{data.desc}</div> */}
    <div className="project-info">{data.desc}</div>
    {
      data.is_show_jump_voucher ? 
      <div className="project-vouch-img">
        <Link to="/voucher">
          <img src="/resources/index/index-project-vouch.gif"/>
        </Link>
      </div>
      :
      null
    }
    
    <div className="project-items">
      {projectGameslist?.map((game, index) => <div className={'project-item' + (index % 4 === 0 ? ' special' : '')}><div onClick={()=>activityData(game)} >
        <div className="item-base">
          <div className="icon">
            <Image src={game.game_image.thumb}/>
            {
              game?.lablePosition == 2 && game.top_lable != '' ?
              <div className="iconlable jx" style={{backgroundImage: 'linear-gradient('+game?.lableColor.t+', '+game?.lableColor.b+')'}}>
                {game.top_lable}
              </div>
              : null
            }
          </div>
          <div className="text">
            <div className="name" style={{width: game.discount < 1 ? '83%' : '100%' }}>
              {/* {game.top_lable != '' ? <span>{game.top_lable }</span> : null }{game.game_name} */}
              <div className={" " + (game.nameRemark ? " name-new" : "")}>
                {game?.lablePosition == 1 && game.top_lable != '' ? <span className="span">{game.top_lable }</span> : null }
                {game.game_name}
              </div>
            </div>
            <div className="info1">
              {/* {
                game.nameRemark ?
                <span className="nameRemark">
                  {game?.nameRemark}&nbsp;
                </span>
                : null
              } */}
              {/* {
                game.nameRemark ?
                <>{game.game_classify_type.replace(/(^\s*)|(\s*$)/g, "").replace(/\s+/g, "&")}</>
                :
                <>{game.game_classify_type.trim()}</>
              }
              {!game.nameRemark && game.game_species_type != 3 ? <>&nbsp;|&nbsp;{game.howManyPlay}人在玩</> : null} */}
              {game.game_classify_type.trim()}
              <Subtitle>{game.nameRemark}</Subtitle>
            </div>
            {/* <div className="info2">
              {game.game_desc ? game.game_desc.split('+').map(item => <div
                className="tag">{item}</div>) : <span style={{color: game.infoColor}}>{game.introduction}</span>}
            </div> */}
            <div className="info3">
              {
                game.bottom_lable ? 
                <>
                {Object.keys(game.bottom_lable).length > 0 ? 
                <div >
                  <span className="first-text" style={{background:game.bottom_lable.type ==1 ? '#FF8C50' : '#9F9DFC'}}>{game.bottom_lable.left_content}</span><span className="second-text" style={{color:game.bottom_lable.type ==1 ? '#FF8C50' : '#9F9DFC',borderColor:game.bottom_lable.type ==1 ? '#FF8C50' : '#9F9DFC'}}>{game.bottom_lable.right_content}</span>
                </div> : null }
                </>
                : null
              }
              {game.exclusive_game_describe?.length > 0 ? <div className="speed">{game.exclusive_game_describe}</div> : null}
              {game.is_support_accele ? <div className="speed">可加速</div> : null}
              {game.is_easy_play ? <div className="speed">省心玩</div> : null}
              {Object.keys(game.bottom_lable).length == 0 && !game.is_support_accele && !game.is_easy_play && game.introduction && game.exclusive_game_describe?.length == 0 ? <span style={{color:'#888'}}>{game.introduction}</span> : null}              </div>
              {game.discount < 1 ? <div className="discount" ><span>{game.discount * 10}</span>折</div> : null}

          </div>
          {/* <div className="btn">下载</div> */}
        </div>
        
        {(()=>{
          if(data.style != '4' && data.style != '5') { 
            return <div>
              <div className="item-img"><Image src={game.image}/></div>
              {
                game.fuli_desc.trim() ? <div className="item-text" dangerouslySetInnerHTML={{
                  __html: game.fuli_desc.substring(0, 90)
                      .replace(/(\r\n|\n\r|\r|\n)+/g, '\n')
                      .replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br>$2')
                }}/>
                : null
              }
            </div>
          }
        })()}
      </div>
      {(()=>{
          if(data.style == '4') {
            return <div>
                <div className="item-gift-text">
                  <div className="text-title" style={{backgroundImage: 'url(/resources/game/style4-bg.png)'}}>
                    {game.packname}
                  </div>
                  <div className="text-info">
                    <div className="info-content">
                      {game.packcontent}
                    </div>
                    <div className="info-btn">
                      {
                        game.isreceived ? <CopyToClipboard text={game.card} onCopy={() => Dialog.info('已复制')}>
                        <div className="btn-copy">复制</div>
                        </CopyToClipboard> :
                        // <div className="btn-receive"  onClick={()=>bouncesShow(game)}>{/*onClick={()=>receive(game)} */}
                        // 领取
                        // </div>
                        <NavLink className="btn-receive" onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            bouncesShow(game);
                            setGamePubId(game.game_id);
                        }}>领取</NavLink>
                      }
                    </div>
                  </div>
                </div>
                <div className="item-gift-desc">
                  <div className="desc-title" style={{backgroundImage: 'url(/resources/game/style4-bg02.png)'}} >
                    <div className="instru-icon">
                      <img src='/resources/game/wh.png' />
                    </div>
                    使用说明
                  </div>
                  <div className="desc-info">
                    <div className="desc-content">
                      {game.use_desc}
                    </div>
                  </div>
                </div>
            </div>
          } else if(data.style == '5') {
            return <div>
              <div className="item-five-gift">
                <div className="five-amount">
                  ￥<span>{game.amount}</span>
                </div>
                <div className="five-desc">
                  {game.voucherDesc}
                </div>
                <div className="operate">
                    {game.received ? 
                    <div className="alreadly-operate-btn">已领取</div>
                    :
                    // <div className="operate-btn five-relave-but"  onClick={() => receiveVouNew(game)} >领取</div>
                    <NavLink className="operate-btn five-relave-but" onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        receiveVouNew(game)
                    }}>领取</NavLink>
                    }
                </div>
              </div>
            </div>
          }
        })()}
      </div>)}
      <CSSTransition in={giftShow} timeout={250} unmountOnExit={true}>
        <div className="gift-detail">
          <div className="gift-mask" onClick={() => setGiftShow(false)}/>
          <div className="gift-container">
            <div className="gift-top">
              <div className="icon">
              <Image src={giftDescInfoImage}/>
              </div>
              <div className="text">
                <div className="name">{giftDescInfo.gift_name}</div>
                <div className="time">有效期：{moment(giftDescInfo.gift_dealine * 1000).format('YYYY-MM-DD')}</div>
              </div>
            </div>
            <div className="gift-main">
              <div className="gift-desc" style={{borderBottom:"1px solid #ededed",padding:"0 0 10px 0"}}>
                <div className="title">礼包说明</div>
                <div className="main">{giftDescInfo.gift_desc}</div>
              </div>
              <div className="gift-desc" style={{borderBottom:"1px solid #ededed",padding:"10px 0"}}>
                <div className="title">使用说明</div>
                <div className="main">{giftDescInfo.gift_introduce}</div>
              </div>
            </div>
            <NavLink className="gift-bottom" onClick={() => receive(giftDescInfo)}>领取</NavLink>
          </div>
        </div>
      </CSSTransition>
      {giftCode ? <div className="dialog active">
        <div className="dialog-mask"/>
        <div className="dialog-content">
          <div className="dialog-modal">
            <div className="modal-body">
              <div className="modal-title">领取成功</div>
              <div className="modal-content">{giftCode}</div>
            </div>
            <div className="modal-btns">
              <div className="modal-btn">
                <CopyToClipboard text={giftCode} onCopy={() => Dialog.info('已复制')}>
                  <div onClick={() => setGiftCode(null)}>复制</div>
                </CopyToClipboard>
              </div>
            </div>
          </div>
        </div>
      </div> : null}
      {
        trumpetState ?
        <div className="game-trumpet-index">
          <div className="trumpet-site">
            <div className="trumpet-main">
                <div className="trumpet-title">
                温馨提示
                </div>
                <div className="info">
                  <p>您游戏中尚未创建小号，暂时无法领取此礼包</p>
                </div>
                <div className="trumpet-btn">
                  <div className="item btn-about" onClick={()=>{setTrumpetState(false)}}>
                    稍后再领
                  </div>
                  <NavLink to={"/game?id="+gamePubId+"&isExists=1"} className="item btn-confirm" onClick={()=>{setTrumpetState(false);setGiftShow(false);}}>
                    <h2>{giftDescInfo.type == 3 ? '进入游戏' : '下载游戏'}</h2>
                  </NavLink>
                </div>
            </div>
          </div>
        </div>
        : null
      }
    </div>
    {/* <div className="view-end">别撩啦！已经到底啦~</div> */}
  </div>;
};